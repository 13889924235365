:root {
  --white: #ffffff;
  --bg-white: #eeeeee;
  --bg-border: #e0e0e0;
  --bg-dark: #1e1e1e;
  --bg-dark2: #171717;
  --bg-primary-nav: #78f4cf2b;
  --color-primary: #70d8c1;
  --color-title-dark: #78F4CE;
  --color-title-light: #5bbea1;
  --color-paragraph: aliceblue;
  --color-annotation: #3C3C53;
  --color-information: #1174f6;
  --color-information-inner: #3f90f946;
  --color-warning: #f6b811;
  --color-error: #f04d35;
  --color-success: #0bf455;
  --popup-shadow: rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
}

body {
  background: var(--bg-white);
  color: var(--bg-dark);
  margin: 0;
  font-family: Montserrat;
}

.homepage {
  display: flex;
}

.dashboard-container {
  height: 100vh;
  display: flex;
  background-color: (var(--bg-dark));
}

.dashboard-container img {
  width: 50px;
  height: 50px;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 var(--bg-border);
  background-color: var(--white);
  padding: 5px 25px; /* Utilisez vw pour un espacement relatif à la largeur de la vue */
  z-index: 999;
  width: 98%; /* Ajustement pour s'assurer que la barre n'est pas trop large */
}

.nav-top-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 2vw; /* Utilisez vw pour un espacement relatif à la largeur de la vue */
}

.flexingnav {
  display: flex;
  align-items: center;
  gap: 8px;
  stroke: black;
}

.flexing-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
  text-align: left;;
}

.flexing-login h4 {
  margin-top: 0;
}

.flexing-login p {
  width: 300px;
}

.flexingnavtop {
  padding-right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  stroke: black;
}

.flexingnavtop svg {
  padding: 10px;
  border-radius: 10px;
}

.flexingnavtop svg:hover {
  background-color: var(--bg-primary-nav);
  stroke: var(--color-primary);
}

.slashing {
  color: var(--bg-border);
  font-weight: 600;
}

nav img {
  width: 40px; /* Taille relative à la largeur de la vue */
}

.left-box {
  position: fixed;
  width: 50px;
  height: 92%;
  background-color: var(--white);
  padding: 20px;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 0 -1px 0 var(--bg-border);
  z-index: 998
}

.left-box-settings {
  position: fixed;
  height: 92%;
  background-color: var(--white);
  padding: 20px 0;
  left: 91px;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 0 1px 0 1px var(--bg-border);
  z-index: 998
}

.items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.link-btn {
  text-align: center;
  border-radius: 10px;
  stroke: black;
  text-decoration: none;
  padding: 10px;
  color: var(--color-annotation);
  font-size: 10px;
  font-weight: 600;
  width: 75%;
}

.link-btn p {
  margin: 0;
}

.link-btn:hover {
  border-color: var(--color-primary);
  stroke: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--bg-primary-nav);
}

.container {
  margin: 30px 24px;
}

.container-doc {
  margin-left: 170px;
  flex-grow: 1;
  overflow-y: auto;
}

.container-home {
  flex-grow: 1;
  overflow-y: auto;
  margin: 60px 20px 20px 120px;
}

.container-home-content {

}

.container-home-content .bloc {
  margin-top: 30px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--popup-shadow);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container-home-content .bloc img {
  width: 200px;
  height: 100%;
}

.bloc .notconnected {
  color: #ccc;
}

.bloc p {
  margin-top: 5px;
}

.bloc .btn-connect{
  text-decoration: none;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
}

.flexing-end {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.gap-end {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  stroke: var(--bg-dark);
}

.gap-end svg:hover {
  stroke: var(--color-primary);
}

.items-end {
  color: var(--color-annotation);
}

h1 {
  color: var(--color-title-light);
  margin-bottom: 0px;
}

h2 {
  color: var(--color-title-light);
}

.desc {
  margin-top: 0;
}

.settings-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.link-btn-settings {
  padding: 5px 10px;
  color: var(--color-annotation);
  text-decoration: none;
  font-size: 13px;
}

.link-btn-settings:hover {
  border-color: var(--color-primary);
  stroke: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--bg-primary-nav);
}

.table-responsive {
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: var(--white);
      tr {
        th {
          padding: 12px;
          color: var(--color-title-light);
          text-align: left;
          border: 1px solid var(--bg-border);
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: var(--white);
        }

        td {
          padding: 12px;
          border: 1px solid var(--bg-border);
          color: var(--color-annotation);
          font-size: 13px;
        }

        td:last-child {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}

.table tbody input{
  padding: 5px;
  border: 1px solid var(--bg-border);
  border-radius: 5px;

}


.table tbody td button{
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
}

.container-settings {
  margin-left: 203px;
  flex-grow: 1;
  overflow-y: auto;
}

.flexing2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexing2 .container-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.block-inputs2 {
  display: flex;
  /*justify-content: space-between;*/
  gap: 20px;
  margin-bottom: 30px;

}

.box {
  border-radius: 10px;
  /*border: 2px solid var(--color-title-dark);*/
  background-color: var(--white);
  padding: 10px;
}

.container-create {
  margin-top: 60px;
  margin-left: 120px;
  margin-right: 20px;
  flex-grow: 1;
  overflow-y: auto;
}

.container-input {
  display: flex;
  /*align-items: center;*/
  padding: 5px 0;
}

.container-input input {
  width: 100%;
  padding: 10px;
  /*border: 1px solid var(--color-title-dark);*/
  border: none;
  background-color: #f4f4f4;
  border-radius: 5px 0 0 5px;
}

.container-input .texter-btn {
  padding: 7px;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 5px 5px 0;
  background: var(--color-title-dark);
  color: var(--color-annotation);
  display: flex;
  align-items: center;
}

.container-input button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexing2 input {
  width: 100%;
  padding: 12px;
  /*border: 1px solid var(--color-title-dark);*/
  border: none;
  background-color: #f4f4f4;
  border-radius: 5px 0 0 5px;
}

.box-export {
  padding: 7px;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 5px 5px 0;
  background: var(--color-title-dark);
  color: var(--color-annotation);
}

.box-export:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.blocked {
  display: none;
}

.vertical-btn {
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 10px;
}

.input-row button {
  padding: 5px;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 10px 10px 0;
  background: var(--color-title-dark);
}

.vertical-btn button {
  padding: 10px;
  border: 1px solid var(--color-title-dark);
  border-radius: 10px;
  background: var(--color-title-dark);
}

.container-input2 {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.container-input2 input {
  padding: 10px;
  border: 1px solid var(--color-title-dark);
  border-radius: 5px 0 0 5px;
}

.container-input2 .texter-btn {
  padding: 7px;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 5px 5px 0;
  background: var(--color-title-dark);
  color: var(--color-annotation);
}

.toggler-wrapper {
  display: block;
  width: 60px;
  height: 35px;
  cursor: pointer;
  position: relative;
}

.toggler-wrapper input[type="checkbox"] {
  display: none;
}

.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
  background-color: #44cc66;
}

.toggler-wrapper .toggler-slider {
  background-color: #ccc;
  position: absolute;
  border-radius: 10px 0 0 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.toggler-wrapper .toggler-knob {
  position: absolute;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
  left: calc(100% - 19px - 5px);
}

.toggler-wrapper.style-1 .toggler-knob {
  width: calc(25px - 6px);
  height: calc(25px - 6px);
  border-radius: 50%;
  left: 5px;
  top: 8px;
  background-color: #fff;
}

.container-input select {
  width: 100%;
  padding: 10px;
}

select {
  /* Reset */
  appearance: none;;
  /*border-color: var(--color-title-dark);*/
  /*border-radius: 0.25em;*/
  border: none;
  background-color: #f4f4f4;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

/* Style de base pour le popup */
.popup {
  background-color: var(--bg-white);
  border: 1px solid var(--popup-shadow);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--popup-shadow);
  padding: 20px;
  position: fixed;
  right: 0%;
  top: 60px;
  width: 80%;
  height: 100%;
  max-width: 400px; /* Plus petit pour un look plus moderne */
  z-index: 1000;
  transition: all var(--transition-speed) ease;
}

.popup h2 {
  margin-top: 0;
}

/* Titre pour chaque groupe (Taille, Couleur, Matière) */
.popup h3 {
  color: var(--color-title-light);
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--color-primary); /* Utilisation de la couleur primaire pour la ligne */
  text-shadow: none;
}

/* Conteneur pour chaque option */
.popup .option{
  display: flex;
  font-family: "Montserrat", sans-serif;
  align-items: center;
  margin: 10px 0;
  overflow-y: auto;
}

/* Style pour les labels pour améliorer la lisibilité */
.popup li {
  list-style: none;
  font-weight: normal;
  color: var(--color-annotation);
  font-size: 15px;
  padding-bottom: 10px;
  width: 100%;
}

/* Bouton de fermeture du popup */
.popup button {
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  float: right;
  transition: background-color var(--transition-speed) ease;
}

.popup button:hover {
  background-color: darken(var(--color-primary), 10%);
}

/* Clearfix pour le flottant */
.popup::after {
  content: "";
  display: table;
  clear: both;
}

.popup-content {
  max-height: 150px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.texter-btn-decli {
  padding: 6px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 5px 5px 0;
  background: var(--color-title-dark);
  color: var(--color-annotation);
}

.parent-settings {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
  gap: 20px; /* Espace entre les blocs */
  padding-right: 20px;
  padding-bottom: 20px;
}

.file-block {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.file-block h3 {
  color: #333;
  margin-top: 0;
}

.option-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 200px;
}

.option-list tr {
  margin-bottom: 5px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option-list button {
  background-color: var(--color-title-dark);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.container-input-settings {
  display: flex;
  align-items: center;
}

.container-input-settings input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-title-dark);
  border-radius: 5px 0 0 5px;
}

.container-input-settings button {
  padding: 7px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-title-dark);
  border-radius: 0 5px 5px 0;
  background: var(--color-title-dark);
  color: var(--color-annotation);
}

.file-block .settings-subtitle {
  color: var(--color-title-light);
  margin-top: 0;
}

.end-step button {
  background-color: var(--color-title-dark);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.end-step {
  display: flex;
  gap: 10px;
}

.doc-container svg {
  visibility: hidden;
  margin-left: 8px; /* Adjust spacing as needed */
}

li .icon-doc {
  visibility: hidden;
}

li:hover .icon-doc {
  visibility: visible;
}

h2:hover svg {
  visibility: visible;
  opacity: 1;
  padding-left: 30px;
}

.doc-content {
  padding-top: 100px;
}

.doc-content p {
  margin: 0;
  font-family: Montserrat;
  color: var(--color-annotation);
  padding-bottom: 10px;
}

.alert h4 {
  margin: 0;
}

.alert p {
  margin: 0;

}

.flexing-alert {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}

.alert {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  border: 3px solid;
  border-radius: 16px;
  background: var(--bg-dark);
  display: none;
  box-shadow: 0 4px 6px var(--popup-shadow);
}

.alert-success {
  border-color: var(--color-success);
  color: var(--color-paragraph);
}

.alert-warning {
  border-color: var(--color-warning);
  color: var(--color-paragraph);
}

.alert-error {
  border-color: var(--color-error);
  color: var(--color-paragraph);
}

.login {}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container form {
  background-color: var(--white);
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px var(--popup-shadow);
  text-align: center;
}

.login-container form .addons {
  margin-bottom: 50px;
}

.login-container form .addons-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  margin-bottom: 50px;
}

.login-container form img {
  width: 100px;
  height: 100px;
}

.login-container form h1 {
  margin: 0;
}

.login-container form .content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-container form input {
  padding: 10px;
  border: 1px solid var(--bg-border);
  border-radius: 5px;
  color: var(--color-annotation);
}

.login-container form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
}

.login-container form p {
  font-size: 13px;
  margin-top: 20px;
}

.login-container form a {
  color: var(--color-primary);
}

.login-container form a:hover {
  text-decoration: underline;
}

.popup-account {
  position: absolute;
  right: 30px;
  top: 70px;
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--popup-shadow);
}

.popup-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popup-inner img {
  width: 100px;
  margin: 0 auto;
}

.popup-inner h3 {
  margin: 0;
  color: var(--color-primary);
  text-align: center;
}

.popup-inner span {
  font-size: 12px;
  text-align: center;
  color: var(--color-annotation);
}

.popup-inner button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.popup-account.slide-in {
  animation: slideIn 0.5s forwards;
}

.popup-account.slide-out {
  animation: slideOut 0.5s forwards;
}

.login .error{
  color: var(--color-error);
}

.error404-page {
  display: flex;
  justify-content: space-between;
  margin: 0 100px;
  align-items: center;
  height: 100vh;
}

.error404-page img {
  width: 50%;
}

.error404-page h1 {
  color: var(--color-title-light);
  font-size: 50px;
  margin: 0;
}

.error404-page p {
  color: var(--color-annotation);
  font-size: 20px;
}

.error404-page .btn-home{
  text-decoration: none;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
}

input[type="checkbox"] {
  appearance: none; /* Supprime l'apparence par défaut */
  background-color: #f0f0f0; /* Couleur de fond */
  margin: 0; /* Réinitialise les marges */
  font: inherit; /* Hérite la police du parent */
  color: currentColor; /* Utilise la couleur de texte du parent */
  width: 20px; /* Largeur */
  height: 20px; /* Hauteur */
  border: 2px solid #dcdcdc; /* Bordure */
  border-radius: 4px; /* Bordures arrondies */
  margin-right: 5px;
  transition: background-color 0.2s, border-color 0.2s; /* Transition douce */
}

/* Style quand le checkbox est coché */
input[type="checkbox"]:checked {
  background-color: var(--color-primary); /* Changement de la couleur de fond */
  border-color: var(--color-title-light); /* Changement de la couleur de la bordure */
}

/* Hover sur le checkbox pour un feedback visuel */
input[type="checkbox"]:hover:not(:checked) {
  border-color: #a5a5a5; /* Assombrit la bordure */
}

.pop-up-information {
  margin-top: 20px;
  background-color: var(--color-information-inner);
  border: 3px solid var(--color-information);
  border-radius: 5px;
  padding: 20px;
  stroke: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
}

.pop-up-information h3 {
  margin: 0;
  color: var(--white);
}

.pop-up-information p {
  color: var(--color-annotation);
}

.pop-up-information span {
  color: var(--white);
  font-weight: bold;
}

.table-responsive-doc {
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: var(--white);
      tr {
        th {
          padding: 12px;
          color: var(--color-title-light);
          text-align: left;
          border: 1px solid var(--bg-border);
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: var(--white);
        }

        td {
          padding: 12px;
          border: 1px solid var(--bg-border);
          color: var(--color-annotation);
          font-size: 13px;
        }
      }
    }
  }
}

.container-create .flexing2 {
  background-color: var(--white);
  padding: 10px;
  border-radius: 10px;
  margin: 30px 0;
}


.flexing-popup {
  display: flex;
  gap: 10px;
}

.flexing-popup h3 {
  margin: 0;
  text-align: left;
}

.flexing-popup p {
  margin: 0;
  font-size: 12px;
  text-align: left;
}



.popup-account-settings {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--popup-shadow);
  padding: 20px 0;
}

.bkg-settings {
  background-color: rgba(30, 30, 30, 0.5);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.popup-inner-settings h2 {
  margin: 0;
  padding: 0;
}

.popup-inner-settings .text h3 {
  margin: 0;
  padding-top: 10px;
}

.popup-inner-settings .text p {
  margin-bottom: 0;
  padding-bottom: 30px;
  font-size: 13px;
  color: var(--color-annotation);
}

.popup-inner-settings .flexing {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.popup-inner-settings {
  display: flex;
}

.popup-inner-settings .left-box-settings {
  position: inherit;
  padding: 0;
  border-right: 1px solid #eeeeee;
}

.popup-inner-settings .container-settings {
  padding: 0 20px;
  margin: 0;
}

.popup-inner-settings .container-settings form {
  display: flex;
  justify-content: space-between;
}

.popup-inner-settings .container-settings form .col {
  display: flex;
  flex-direction: column;
}

.code-declinaison {
  font-size: 13px;
  color: #979797;
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.table {
  min-width: 100%;
  background-color: white;
  border: 1px solid #D1D5DB; /* Gray-300 */
  border-radius: 0.375rem; /* Rounded-lg */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Shadow-md */
}

.table th {
  background-color: #E5E7EB; /* Gray-200 */
  color: #4B5563; /* Gray-600 */
  text-transform: uppercase;
  font-size: 0.875rem; /* text-sm */
  line-height: 1.5; /* leading-normal */
}

.table th, .table td {
  padding: 12px 24px; /* py-3 px-6 */
  text-align: left;
  height: 1cm !important;
  font-size: 12px;
}

.table tr:hover {
  background-color: #F3F4F6; /* Gray-100 */
}