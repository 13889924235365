body {
  font-family: 'Montserrat', sans-serif;
  color: #3b3b3b;
}

.app {
  position: absolute;
  top: 90px;
  margin-left: 100px;
}

#form {
  display: flex;
  align-items: center;
}

input{
  padding: 5px;
  border: 1px solid var(--bg-border);
  border-radius: 5px;
}

input, button {
  font-family: 'Montserrat', sans-serif;
}

button {
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--white);
  cursor: pointer;
  padding: 7px;
}

button:hover {
    background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #e9e9e9), color-stop(1, #f9f9f9));
    background:-moz-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
    background:-webkit-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
    background:-o-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
    background:-ms-linear-gradient(top, #e9e9e9 5%, #f9f9f9 100%);
    background:linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9e9e9', endColorstr='#f9f9f9',GradientType=0);
    background-color:#e9e9e9;
}

button:active {
    position:relative;
    top:1px;
}


table#etiquettes {
  width: 21cm;
  border-collapse: collapse;
}
tr {
  border: 2px solid #c3c3c3;
}
td {
  height: 3cm;
}

.title {
  padding-left: 0.5cm;
  color: #3b3b3b;
  font-size: 30pt;
  width: 15cm;
  overflow: hidden;
}

.price:not(:empty):after {
   /*content: "\00a0€"; */
   content: "€";
}

.public_price {
  text-decoration: line-through;
}

.selling_price {
  color: #ef0808;
  font-weight: bold;
  font-size: 26pt;
}
.small {
  font-size: 15pt;
}

.pink {
  color: #ef0855;
}

.pink_title {
  /* font-family: 'Great vibes', sans-serif; */
  font-family: 'Courgette', sans-serif;
  letter-spacing: -2px;
  font-size: 35pt;
  color: #ef0855;
}

.img_prix_gp {
  width: 1cm;
}
.prix_gp_container {
  vertical-align: top;
  margin: 0px;
  padding: 5px 5px 0px 0px;
  width: 1cm;
}
.acenter {
  text-align: center;
}

.box2 {
  border-radius: 10px;
  /*border: 2px solid var(--color-title-dark);*/
  background-color: var(--white);
  padding: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}


@media print {
  body * {
    visibility: hidden;
  }
  .app {
    position: absolute;
    top: 0;
    margin-left: 0px;
    left: 0;
  }
  #etiquettes, #etiquettes * {
    visibility: visible;
  }
  #etiquettes {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 0px;
    margin-left: 0px;
  }

  table#etiquettes {
    width: 21cm;
    border-collapse: collapse;
  }
}